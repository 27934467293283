import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";

// Prototype Validation
import PropTypes from "prop-types";

// AWS Amplify - Auth
import { fetchUserAttributes } from "aws-amplify/auth";

// AWS Amplify - API - Request Handler
import { handleGet, handlePut } from "../utils/amplifyInstance";

// AWS Amplify - Storage
import { uploadData } from "aws-amplify/storage";

// Auth Session
import { useSession } from "./AuthSession";

const MainContext = createContext({});

const MainContextProvider = ({ children }) => {
  const { username, cognito_identity_uuid, userSub, authToken } = useSession();
  const [account, setAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companyid, setCompanyId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [accountid, setAccountId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [onlyLTD, setOnlyLTD] = useState(null);

  // Auth Context - User
  useEffect(() => {
    let cancel = false;

    const fetchUser = async () => {
      if (!username) {
        return;
      }

      try {
        if (cancel) {
          return;
        }
        
        // Check if the profile is admin
        const { profile } = await fetchUserAttributes();
        
        // If Admin user set the OpenDialog to true. Otherwise set username.
        // Please set account id's in Cognito AWS user pool and add profile attribute = admin
        if (profile === "admin") {
          setOpenDialog(true);
        } else {
          setAccountId(username);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchUser();

    return () => {
      cancel = true;
    };
  }, [username]);

  // Auth Context -  Account Information
  useEffect(() => {
    let cancel = false;

    const fetchAccount = async () => {
      if (!accountid || !authToken) {
        return;
      }

      try {
        const response = await handleGet({
          apiName: "EmployerApiGw",
          path: `/employer/${accountid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        
        if (cancel) {
          return;
        }

        const { body } = await response;
        const json = await body.json();

        if (json.items.length > 0) {
          setAccount(json.items);
          setIsValid(true);
          setOpenDialog(false);
        } else {
          setAccount([]);
          setIsValid(false);
        }
      } catch (e) {
        console.log(e);
        setAccount([]);
        setIsValid(null);
      }

      setIsLoading(false);
    };

    fetchAccount();

    return () => {
      cancel = true;
    };
  }, [accountid, authToken]);

  const handleUpload = useCallback(async (path, data, options = {}) => {
    try {
      await uploadData({
        path,
        data,
        ...options,
      });
    } catch (error) {
      console.error("Upload file failed:", error);
    }
  }, []);

  // Auth Context -  PUT S3 Bucket - ORACLE ACCOUNT_PORTAL
  useEffect(() => {
    let cancel = false;

    const postAccount = async () => {
      if (!username) {
        return;
      }

      // User Attributes
      const { email, mobile, profile } = await fetchUserAttributes();

      if (
        profile === "admin" ||
        !Array.isArray(account) ||
        !account.length
      ) {
        return;
      }

      try {
        if (
          cognito_identity_uuid !== account.cognito_identity_uuid ||
          account.cognito_identity_uuid === null ||
          account.cognito_uuid === null
        ) {

          var init = {
            cognito_uuid: userSub,
            cognito_identity_uuid: cognito_identity_uuid,
            email: email,
            mobile: mobile ? mobile : "",
          };

          const accountid = account.map((i) => i.accountid);
          try {
            await handlePut({
              apiName: "EmployerApiGw",
              path: `/employer/${accountid}`,
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
              body: init,
            });
          } catch (e) {
            console.log(e);
          }

          if (cancel) {
            return;
          }

          // Upload File init.txt initialize S3 Bucket
          handleUpload(
            `protected/${cognito_identity_uuid}/init.txt`,
            "ignore",
            {
              contentType: "text/plain",
              metadata: {
                level: "protected",
              },
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    postAccount();

    return () => {
      cancel = true;
    };
  }, [
    account,
    username,
    cognito_identity_uuid,
    userSub,
    authToken,
    handleUpload,
  ]);

  return (
    <MainContext.Provider
      value={{
        account,
        setAccount,
        isLoading,
        setIsLoading,
        companyid,
        setCompanyId,
        openDialog,
        setOpenDialog,
        isValid,
        setIsValid,
        accountid,
        setAccountId,
        drawerOpen,
        setDrawerOpen,
        onlyLTD,
        setOnlyLTD,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useMainContext = () => useContext(MainContext);

export default MainContextProvider;
