import {
  isCompleteDate,
  isValidNumber,
  validSIN
} from "../common/HelperFunctions";

export const validateCompanyAddress = (data) => {
  var errors = {};

  Object.keys(data).map(k => data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]);

  for (const key in data) {
    switch (key.toString()) {
      case "address1":
        if (data.address1.length === 0) {
          errors.address1 = "This field is required";
        }
        break;
      case "address2":
        break;
      case "city":
        if (!/^[A-Za-z0-9 '-.]+$/.test(data.city)) {
          errors.city = 'City is invalid';
        }
        break;
      case "postalcode":
        if (((data.country === "CANADA" || data.country === "") && !/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]( )?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(data.postalcode))
          || (data.country === "UNITED STATES" && !/^\d{5}(-\d{4})?$/.test(data.postalcode))) {
          errors.postalcode = 'Postal Code is invalid';
        }
        break;
      default:
        break;
    }
  }

  return errors;
};

export const validatePassword = (values) => {
  var errors = {};

  for (const key in values) {
    switch (key.toString()) {
      case "oldPassword":
        if (values.oldPassword.length === 0) {
          errors.oldPassword = "This field is required";
        }
        break;
      case "newPassword":
        if (!/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(values.newPassword)) {
          errors.newPassword = "Password must have at least 1 uppercase, 1 lowercase, 1 digit, 1 special character and a length of at least of 8";
        }
        break;
      case "repeatPassword":
          if (values.repeatPassword !== values.newPassword)  {
          errors.repeatPassword = "Password doesn't match";
        }
        break;
      default:
        break;
    }
  }

  return errors;
};

// export const validPostalCode = (match, p1, p2, offset, string) => {
//   return [p1, p2].join(" ");
// }

export const validPhoneFormat = (match, p1, p2, p3, offset, string) => {
  return ["(", p1, ")"].join("") + [p2, p3].join("-");
}

export const validSINFormat = (match, p1, p2, p3, offset, string) => {
  return [p1, p2, p3].join("-");
}

export const validateContactInfo = (data) => {
  var errors = {};

  Object.keys(data).map(k => data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]);

  for (const key in data) {
    switch (key.toString()) {
      case "surname":
        if (data.surname.length === 0) {
          errors.surname = 'Surname is required';
        } else {
          if (!/^([A-Za-z '()-.]+)$/.test(data.surname)) {
            errors.surname = 'Surname is invalid';
          }
        }
        break;
      case "givennames":
        if (data.givennames.length === 0) {
          errors.givennames = 'First name is required';
        } else {
          if (!/^([A-Za-z '()-.]+)$/.test(data.givennames)) {
            errors.givennames = 'First name is invalid';
          }
        }
        break;
      case "address1":
        if (data.address1.length === 0) {
          errors.address1 = "This field is required";
        }
        break;
      case "address2":
        break;
      case "city":
        if (data.city.length === 0) {
          errors.city = "This field is required";
        } else {
          if (data.usecompany === "N"
            && !/^[A-Za-z0-9 '-.]+$/.test(data.city)) {
            errors.city = 'City is invalid';
          }
        }
        break;
      case "postalcode":
        if (data.postalcode.length === 0) {
          errors.postalcode = "This field is required";
        } else {
          if (data.usecompany === "N"
            && (((data.country === "CANADA" || data.country === "") && !/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]( )?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(data.postalcode))
            || (data.country === "UNITED STATES" && !/^\d{5}(-\d{4})?$/.test(data.postalcode)))) {
            errors.postalcode = 'Postal Code is invalid';
          }
        }
        break;
      default:
        break;
    }
  }

  return errors;
};

export const validateEmployeeInfo = (data) => {
    var errors = {};

    Object.keys(data).map(k => data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]);

    for (const key in data) {
      switch (key.toString()) {
        case "sin":
          if (data.sin.length === 0) {
            errors.sin = 'SIN is required';
          } else {
            if (data.sin.length > 0 && !validSIN(data.sin)) {
              errors.sin = 'SIN is invalid';
            }
          }
          break;
        case "surname":
          if (data.surname.length === 0) {
            errors.surname = 'Surname is required';
          } else {
            if (!/^([A-Za-z '()-.]+)$/.test(data.surname)) {
              errors.surname = 'Surname is invalid';
            }
          }
          break;
        case "givennames":
          if (data.givennames.length === 0) {
            errors.givennames = 'First name is required';
          } else {
            if (!/^([A-Za-z '()-.]+)$/.test(data.givennames)) {
              errors.givennames = 'First name is invalid';
            }
          }
          break;
        case "address1":
          if (data.address1.length === 0) {
            errors.address1 = 'Address line 1 is required';
          }
          break;
        case "address2":
          break;
        case "city":
          if (data.city.length === 0) {
            errors.city = 'City is required';
          } else {
            if (!/^[A-Za-z0-9 '-.]+$/.test(data.city)) {
              errors.city = 'City is invalid';
            }
          }
          break;
        case "postalcode":
          if (!/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]( )?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(data.postalcode)) {
            errors.postalcode = 'Postal Code is invalid';
          }
          break;
        case "phone":
          if (data.phone.length === 0) {
            errors.phone = 'Phone number is required';
          } else {
            if (!/^[0-9]{10}$/.test(data.phone)) {
              errors.phone = 'Phone number is invalid';
            }
          }
          break;
        case "emailaddress":
          if (!/^([\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6})?$/.test(data.emailaddress)) {
            errors.emailaddress = 'Email address is invalid';
          }
          break;
        case "sex":
          if (data.sex.length === 0) {
            errors.sex = 'Gender is required';
          }
          break;
        case "birthdate":
          if (data.birthdate.length === 0) {
            errors.birthdate = 'Date of Birth is required';
          }
          break;
        case "hiredate":
          if (data.hiredate.length === 0) {
            errors.hiredate = 'Hire date is required';
          }
          break;
        default:
          break;
      }
    }

    return errors;
};

export const validateMakePADInfo = (data) => {
  var errors = {};

  Object.keys(data).map(k => data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]);

  for (const key in data) {
    switch (key.toString()) {
      case "onetime_startdate":
        if (!isCompleteDate(data.onetime_startdate)) {
          errors.onetime_startdate = 'Payment date is invalid';
        }
        break;
      case "onetime_startamount":
        if (!isValidNumber(data.onetime_startamount)) {
          errors.onetime_startamount = 'Amount is invalid';
        } else {
          if (data.onetime_startamount <= 0) {
            errors.onetime_startamount = 'Amount must be > 0';
          }
        }
        break;
      default:
        break;
      }
    }

  return errors;
};